<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
            v-model="dialogEdit"
            max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                    Добавить категорию
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    :sm="12"
                                    :md="12"
                                >
                                    <v-text-field
										v-model="editedItem.name"
										label="Название категории"
										@keyup.enter="save"
                                        autofocus
                                        @change="haveEditorChanges = true"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление категории</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить указанную категорию?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить категорию"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить категорию"
            >
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'Tags',
    data() {
        return {
            list: [],
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'count',
                    text: 'Количество новостей',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            hasNext: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [5, 10, 20, -1]
            },
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
            },
            dialogRemove: false,
            itemToRemove: -1,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
    },
    mounted() {
        this.getList(this.pagination.page);
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новая категория' : 'Редактирование категории #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/tags?${new URLSearchParams({
                    pg,
                    on,
                })}`);

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        editItem (item) {
            this.editedIndex = this.list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogEdit = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api('/admin/tags/' + item.id, {
                    method: 'DELETE'
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api('/admin/tags/' + this.editedItem.id, {
                        method: 'PUT',
                        body: {
                            name: this.editedItem.name
                        }
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api('/admin/tags', {
                        method: 'POST',
                        body: {
                            name: this.editedItem.name
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
    }
};
</script>
